
  import { defineComponent, onBeforeUnmount, Ref, ref } from 'vue';
  import { Dialog, Toast } from 'vant';
  import { uploadImage } from '@/api/universal/file'
  import Recorder from 'recorder-core/recorder.mp3.min'

  /**
   * 音频相关初始化和方法
   * @param emit
   */
  const useAudioEffect = (audioUrl:any ) => {
    // 音频控制面板显示
    const audioTag = ref(false)
    const startTag = ref(true)
    // const audioUrl = ref("")
    const btnText = ref('点击下方按钮开始录音')
    const duration = ref(0.00)
    const recorder = Recorder({
      type: 'mp3',
      bitRate: 16,
      sampleRate: 16000,
      onProcess: function (buffers: any, powerLevel: any, bufferDuration: any, bufferSampleRate: any){
        duration.value = Number((bufferDuration / 1000).toFixed(2))
      }
    })

    const showAudioControls = () => {
      if (audioUrl.value) {
        Dialog.confirm({
          title: '确认',
          message: '已存在录音，是否需要删除重新录音？'
        }).then(() => {
          audioUrl.value = ''
          audioTag.value = !audioTag.value
        })
      } else {
        audioTag.value = !audioTag.value
      }
    }

    const openRecorder = () => new Promise((resolve) => {
      recorder.open(() => {
        resolve(true)
        startTag.value = false
      }, (msg: string, isNotAllow: boolean) => {
        if (isNotAllow) {
          Toast.fail('打开录音失败！')
        }
      })
    })
    const resetRecorder = () => {
      recorder.close()
      btnText.value = '点击下方按钮开始录音'
      startTag.value = true
    }
    const delAudio = (url: Ref) => {
      url.value = ''
    }
    // 开始录音
    const startRecord = () => {
      if (audioUrl.value) {
        Dialog.confirm({
          title: '确认',
          message: '已存在录音，是否需要删除重新录音？'
        }).then(() => {
          audioUrl = ''
          if (recorder) {
            openRecorder().then(() => {
              recorder.start()
              btnText.value = '录音中'
            })
          }
        })
      } else {
        if (recorder) {
          openRecorder().then(() => {
            recorder.start()
            btnText.value = '录音中'
          })
        }
      }
    }

    // 结束录音
    const endRecord = () => {
      recorder.stop((blob: Blob) => {
        const uploadDirectory = 'mmdaily-voice'
        uploadImage(uploadDirectory, new File([blob], '录音.mp3')).then(r => {
          audioUrl = r
          audioTag.value = !audioTag.value
        })
        resetRecorder()
      }, (msg: string) => {
        resetRecorder()
        Toast.fail('录音失败:' + msg)
      })
    }

    return {
      recorder,
      audioUrl,
      resetRecorder,
      delAudio,
      showAudioControls,
      audioTag,
      startTag,
      startRecord,
      endRecord,
      duration
    }
  }

  export default defineComponent({
    name: 'UploadAudio',
    props: {
      audioUrl: {
        type: String,
        required: true
      }
    },
    setup(props, {emit}) {
      // 音频上传
      // 音频控制面板显示
      const audioTag = ref(false)
      const startTag = ref(true)
      // const audioUrl = ref("")
      const btnText = ref('点击下方按钮开始录音')
      const duration = ref(0.00)
      const recorder = Recorder({
        type: 'mp3',
        bitRate: 16,
        sampleRate: 16000,
        onProcess: function (buffers: any, powerLevel: any, bufferDuration: any, bufferSampleRate: any){
          duration.value = Number((bufferDuration / 1000).toFixed(2))
        }
      })
      
      //let { recorder, audioUrl, showAudioControls, audioTag, startTag, startRecord, endRecord, duration} =  useAudioEffect(props.audioUrl)
      // 组件销毁，关闭录音
      onBeforeUnmount( () => {
        recorder.close()
      })
      const showAudioControls = () => {
      if (props.audioUrl) {
        Dialog.confirm({
          title: '确认',
          message: '已存在录音，是否需要删除重新录音？'
        }).then(() => {
          emit('update:audioUrl', '')
          audioTag.value = !audioTag.value
        })
      } else {
        audioTag.value = !audioTag.value
      }
    }

    const openRecorder = () => new Promise((resolve) => {
      recorder.open(() => {
        resolve(true)
        startTag.value = false
      }, (msg: string, isNotAllow: boolean) => {
        if (isNotAllow) {
          Toast.fail('打开录音失败！')
        }
      })
    })
    const resetRecorder = () => {
      recorder.close()
      btnText.value = '点击下方按钮开始录音'
      startTag.value = true
    }
    const delAudio = (url: Ref) => {
      url.value = ''
    }
    // 开始录音
    const startRecord = () => {
      if (props.audioUrl) {
        Dialog.confirm({
          title: '确认',
          message: '已存在录音，是否需要删除重新录音？'
        }).then(() => {
          emit('update:audioUrl', '')
          if (recorder) {
            openRecorder().then(() => {
              recorder.start()
              btnText.value = '录音中'
            })
          }
        })
      } else {
        if (recorder) {
          openRecorder().then(() => {
            recorder.start()
            btnText.value = '录音中'
          })
        }
      }
    }

    // 结束录音
    const endRecord = () => {
      recorder.stop((blob: Blob) => {
        const uploadDirectory = 'mmdaily-voice'
        uploadImage(uploadDirectory, new File([blob], '录音.mp3')).then(r => {
          emit('update:audioUrl', r)
          audioTag.value = !audioTag.value
        })
        resetRecorder()
      }, (msg: string) => {
        resetRecorder()
        Toast.fail('录音失败:' + msg)
      })
    }

      const askDel = () => {
        Dialog.confirm({
          title: '确认',
          message: '确认删除录音吗？'
        }).then(() => {
          emit('update:audioUrl', '')
        }).catch(() => {})
      }
      return {
        askDel,
        showAudioControls, audioTag, startTag, startRecord, endRecord, duration
      }
    }
  })
